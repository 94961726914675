import {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import { db } from "./Db";

export const UserContext = createContext();

export const Axios = axios.create({
    baseURL: 'php-react/',
});

export const UserContextProvider = ({children}) => {

    const [theUser, setUser] = useState(null);

    const [wait, setWait] = useState(false);

    // Logowanie

    const loginUser = async (formData) => {

        setWait(true);

        try {

            const {data} = await Axios.post('login.php', {formData});

            if(data.token){

                if(userDB.length === 0){

                    createUser(data);    

                } else {

                    updateUser(data);

                }

                setUserDB([{username: data.username, role: data.role, token: data.token}]);

                setWait(false);

                return data;

            }

            setWait(false);

            return {message: data.message};

        } catch(err){

            setWait(false);

            return {success:0, message:'Błąd serwera!'};

        }

    }

    // Wpisywanie użytkownika do lokalnej bazy danych

    const createUser = async (data)=>{

        await db.user.put({index: 1, username: data.username, user_id: data.user_id, token: data.token});

    }

    // Aktualizacja wpisu użytkownika do lokalnej bazy danych

    const updateUser = async (data)=>{

        await db.user.update(1, {username: data.username, user_id: data.user_id, token: data.token});

    }

    // Pobieranie użytkownika z lokalnej bazy danych

    const [userDB, setUserDB] = useState(null);

    useEffect(() => {

        db.user.toArray().then(function(result){

            setUserDB(result);
            
        });

    },[]);

    // Sprawdzanie użytkownika wpisanego do lokalnej bazy danych

    useEffect(() => {

        if(Array.isArray(userDB)){

            asyncCall();

        }

        async function asyncCall(){

            await loggedInCheck();
            
        }
        
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[userDB]);

    const loggedInCheck = async () => {

        if(userDB.length > 0){

            Axios.options('getUser.php', { timeout: 2500 }).then(function(){

                logOnline();

            }).catch((error) => {

                console.log(error);

                setUser({name: '', user_id: '', role: 'none'});

            });

        } else {

            setUser({name: '', user_id: '', role: 'none'});

        }
        
    }

    const logOnline = async () => {

        const loginToken = userDB[0].token;

        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

        if(loginToken){

            const {data} = await Axios.get('getUser.php');

            if(data.success && data.user){

                let userData = data.user[0];

                setUser({name: userData.username, user_id: userData.user_id, role: userData.role});

                return;

            } else if(data.success === 0 && data.message === "User not found"){

                setUser({name: '', user_id: '', role: 'none'});

            } else {

                setUser({name: '', user_id: '', role: 'none'});

            }

        } else {

            setUser({name: '', user_id: '', role: 'none'});

        }

    }

    // Wylogowanie

    const logout = () => {
        db.user.clear();
        setUser(null);
        window.location.reload(true);
    }

    return (
        <UserContext.Provider value={{loginUser, wait, user:theUser, loggedInCheck, logout}}>
            {children}
        </UserContext.Provider>
    );

}

export default UserContextProvider;