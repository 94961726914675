import React, { useState, useContext } from "react";
import {UserContext, Axios} from './UserContext';
import { useLocation } from "react-router-dom";

export default function Login(){

    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const user_id = params.get("user_id"); 

    const auth = params.get("auth"); 

    const [screen, setScreen] = useState(1);

    const {loginUser, loggedInCheck} = useContext(UserContext);

    const [message, setMessage] = useState(null);

    const [formData, setFormData] = useState(
        {
            username_email: '',
            password: '',
            email_remind: '',
            reset_1: '',
            reset_2: ''
        }
    );

    function formChange(event){

        const {name, value} = event.target;

        setMessage(null);

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });

    }

    const sendLogin = async(event) => {

        event.preventDefault();

        if(!formData.username_email || !formData.password){

            showModal('error', 'Proszę wypełnić oba pola!');

        } else {

            const data = await loginUser(formData);

            if(data.token){

                setMessage(data.message);

                await loggedInCheck();

            } else {

                showModal('error', data.message);

            }

        }

    }

    function initiatePasswordReset(){

        let email = formData.email_remind;

        let mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        let error;

        if(!email){

            error = 'Wpisz adres email, na który jest zarejstrowane konto.';

        } else if(!email.match(mailRegex)){

            error = 'Wprowadzono nieprawidłowy adres email.';

        }

        if(error){

            showModal('error', error);

        } else {

            let formData = {email_remind: email};

            Axios.post('login.php', { formData }, { timeout: 5000 }).then(function(response){

                if(response.data.message){

                    let message = response.data.message;

                    let type = response.data.success ? 'info' : 'error';

                    showModal(type, message);

                } else {

                    showModal('error', 'Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie później.');
                    
                }

            }).catch((error) => {

                console.warn(error);

                showModal('error', 'Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie później.');

            });

        }

    }

    const [redirect, setRedirect] = useState(false);

    function resetPassword(){

        const new_password = formData.reset_1;

        const new_password_repeat = formData.reset_2;

        let error;

        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

        if(!new_password || !new_password_repeat){

            error = 'Uzupełnij oba pola formularza!';

        } else if(new_password !== new_password_repeat){

            error = 'Podane hasła nie są identyczne!';

        } else if(!regex.test(new_password)){

            error = 'Hasło musi mieć co najmniej 8 znaków, jedną wielką literę, jedną cyfrę i znak specjalny.';

        }

        if(error){

            showModal('error', error);

        } else {

            let formData = { user_id: user_id, auth: auth, new_password: new_password};

            Axios.post('login.php', { formData }, { timeout: 5000 }).then(function(response){

                if(response.data.message){

                    let message = response.data.message;

                    let type = response.data.success ? 'info' : 'error';

                    showModal(type, message);

                    if(response.data.redirect){

                        setRedirect(true);

                    }

                } else {

                    showModal('error', 'Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie później.');
                    
                }

            }).catch((error) => {

                console.warn(error);

                showModal('error', 'Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie później.');

            });

        }

    }

    // Modal

    const [modalMessage, setModalMessage] = useState(null);

    const [modal, setModal] = useState({
        show: false,
        info: false,
        error: false
    });

    function closeModal(){

        setModalMessage(null);

        Object.keys(modal).forEach(key => modal[key] = false);

        if(redirect){

            window.history.replaceState(null, "", window.location.pathname);

            window.location.reload();

        }

    }

    function showModal(type, message){

        setModalMessage(message);

        setModal({...modal, show: true, [type]: true});

    }

    return (

        <div id="login-outer-container">
            <div id="login-inner-container">
                <div id="login-inner-1">
                    {(!user_id || !auth) && 
                        <div className="login-inner-2">
                            {screen === 1 && 
                                <div id="login">
                                    <div className="login-field">
                                        <input 
                                            className="login-form"
                                            id="username_email"
                                            type="text"
                                            onChange={formChange}
                                            name="username_email"
                                            value={formData.username_email}
                                            placeholder="Użytkownik lub email"
                                        />
                                    </div>
                                    <div className="login-field">
                                        <input 
                                            className="login-form"
                                            id="password"
                                            type="password"
                                            onChange={formChange}
                                            name="password"
                                            value={formData.password}
                                            placeholder="Hasło"
                                        />
                                    </div>
                                    <p className="login-button-wrapper">
                                        <button className="send-login" onClick={sendLogin}><span className="send-login-text">Zaloguj się</span></button>	
                                        {message && <span className="login-message">{message}</span>}
                                    </p>
                                    <button className="login-screen-change" onClick={() => setScreen(2)}>Nie pamiętam hasła</button>
                                </div>
                            }
                            {screen === 2 && 
                                <div id="remind">
                                    <div className="login-field">
                                        <p id="remind-message">Podaj adres email, na który jest zarejestrowane konto:</p>
                                    </div>
                                    <div className="login-field">
                                        <input 
                                            className="login-form"
                                            id="email_remind"
                                            type="text"
                                            onChange={formChange}
                                            name="email_remind"
                                            value={formData.email_remind}
                                            placeholder="Adres email"
                                        />
                                    </div>
                                    <p className="login-button-wrapper">
                                        <button className="send-login" onClick={initiatePasswordReset}><span className="send-login-text">Resetuj hasło</span></button>	
                                    </p>
                                    <button className="login-screen-change" onClick={() => setScreen(1)}>Wróć do logowania</button>
                                </div>
                            }
                        </div>
                    }
                    {user_id && auth && 
                        <div className="login-inner-2">
                            <div id="reset">
                                <input type="text" style={{ display: 'none' }} />
                                <input type="text" style={{ display: 'none' }} autoComplete="new-password" />
                                <div className="login-field">
                                    <input 
                                        className="login-form"
                                        id="reset_1"
                                        type="password"
                                        autoComplete={'off'}
                                        onChange={formChange}
                                        name="reset_1"
                                        value={formData.reset_1}
                                        placeholder="Nowe hasło"
                                    />
                                </div>
                                <div className="login-field">
                                    <input 
                                        className="login-form"
                                        id="reset_2"
                                        type="password"
                                        autoComplete={'off'}
                                        onChange={formChange}
                                        name="reset_2"
                                        value={formData.reset_2}
                                        placeholder="Powtórz hasło"
                                    />
                                </div>
                                <p className="login-button-wrapper">
                                    <button className="send-login" onClick={resetPassword}><span className="send-login-text">Zmień hasło</span></button>	
                                </p>
                            </div>
                        </div>
                    }
                    <div id="login-background">
                        <span id="login-background-shape4" className="login-background-shape"></span>
                        <span id="login-background-shape3" className="login-background-shape"></span>		
                        <span id="login-background-shape2" className="login-background-shape"></span>
                        <span id="login-background-shape1" className="login-background-shape"></span>
                    </div>		
                </div>
            </div>
            {modal.show &&
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal" onClick={(e)=>e.stopPropagation()}>
                        <div className={modal.info ? "modal-header" : "modal-header modal-header-error"}>
                            <h2 className="modal-title">{modal.info ? 'Informacja' : 'Błąd'}</h2>
                        </div>
                        <div className="modal-body">
                            <div className="modal-wrapper">
                                <p className="modal-text">{modalMessage}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="modal-single-button-wrapper"> 
                                <button className={modal.info ? "modal-single-button" : "modal-single-button modal-single-error-button"} onClick={closeModal}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};