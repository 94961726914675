import { useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { UserContext } from './components/UserContext';
import { Common, Login, Main, Admin } from './index';

function App() {

    useEffect(() => {
        document.title = 'React';
    }, []);

    const {user} = useContext(UserContext);

    return (
        
        <>
            {user &&
                <>
                    {user.role === 'none' && (
                        <BrowserRouter>
                            <Routes>
                                <Route path='/' element={<Common />}>
                                    <Route index element={<Login />} />
                                    <Route path='*' element={<Navigate to='/' />} />
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    )}
                    {user.role === 'admin' && (
                        <BrowserRouter>
                            <Routes>
                                <Route path='/' element={<Common />}>
                                    <Route index element={<Main />} />
                                    <Route path='/admin' element={<Admin />} />
                                    <Route path='*' element={<Navigate to='/' />} />
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    )}
                </>
            }
        </>
    );

}

export default App;