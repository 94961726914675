import React, { useState, useContext, useEffect } from "react";
import { UserContext, Axios } from './UserContext';

export default function Main(){

    useEffect(() => {

        generateCode();

        function generateCode(){

            const letters = 'ABCDEFGHIJKLMNOPRSTUWXYZ';
    
            const number = '0123456789';
    
            let code = '';
    
            for(let i = 1; i <= 6; i++){
    
                if(i <= 3){
    
                    let random = Math.floor(Math.random() * (23 - 0) + 0);
    
                    code = code + letters.charAt(random);
    
                } else {
    
                    let random = Math.floor(Math.random() * (9 - 0) + 0);
    
                    code = code + number.charAt(random);
    
                }
    
            }
    
            sessionStorage.setItem("OTPIdentifier", code);
    
        }

    }, []);

    const {user} = useContext(UserContext);

    const [formData, setFormData] = useState(
        {
            phone: '',
            otp: ''
        }
    );
    
    function formChange(event){
    
        const {name, value} = event.target;

        if(/^\d*$/.test(value) || value === ""){

            setFormData(prevFormData => {
                return {
                    ...prevFormData,
                    [name]: value
                }
            });
            
        }
    
    }

    
    
    function verifyPhone(){

        let phone_number = formData.phone;

        let error;

        if(!phone_number){

            error = 'Proszę wpisz numer telefonu.';

        } else if(!(/^\d{9}$/.test(phone_number))){

            error = 'Wprowadź poprawny numer telefonu(wyłącznie 9 cyfr).';

        }

        if(error){

            showModal('error', error);

            return;

        }

        let otp_identifier = sessionStorage.getItem("OTPIdentifier");

        let user_id = user.user_id;

        Axios.post('classes/sms.php', { phone_number, otp_identifier, user_id }, { timeout: 5000 }).then(function(response){

            if(response.data.message){

                let message = response.data.message;

                let type = response.data.success ? 'info' : 'error';

                showModal(type, message);

            } else {

                showModal('error', 'Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie później.');
                
            }

        }).catch((error) => {

            console.warn(error);

            showModal('error', 'Wystąpił błąd w trakcie przetwarzania danych. Spróbuj ponownie później.');

        });

    }

    // Modal

    const [modalMessage, setModalMessage] = useState(null);

    const [modal, setModal] = useState({
        show: false,
        info: false,
        error: false
    });

    function closeModal(){

        setModalMessage(null);

        Object.keys(modal).forEach(key => modal[key] = false);

    }

    function showModal(type, message){

        setModalMessage(message);

        setModal({...modal, show: true, [type]: true});

    }

    const [OTPError, setOTPError] = useState(null);

    if("OTPCredential" in window) {

        window.addEventListener("DOMContentLoaded", () => {
        
            navigator.credentials.get({

                otp: { transport: ["sms"] },
                signal: new AbortController().signal,
                
            }).then((otp) => {

                setFormData(prevFormData => {
                    return {
                        ...prevFormData,
                        otp: otp.code
                    }
                });

            }).catch((err) => setOTPError(err));

        });

    }

    return (
        <div id="app-outer-container">
            <div id="app-inner-container">
                <input 
                    className="login-form"
                    id="phone"
                    type="text"
                    autoComplete={'off'}
                    onChange={formChange}
                    inputMode="numeric"
                    name="phone"
                    value={formData.phone}
                    placeholder="Numer telefonu"
                />
                <input 
                    className="login-form"
                    id="otp"
                    type="text"
                    autoComplete="one-time-code"
                    onChange={formChange}
                    name="otp"
                    value={formData.otp}
                    placeholder="Kod"
                />
                {OTPError && <p id="error-text">{OTPError}</p>}
                <p className="login-button-wrapper">
                    <button className="send-login send-login-2" onClick={verifyPhone}><span className="send-login-text">Testuj</span></button>	
                </p>
            </div>
            {modal.show &&
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal" onClick={(e)=>e.stopPropagation()}>
                        <div className={modal.info ? "modal-header" : "modal-header modal-header-error"}>
                            <h2 className="modal-title">{modal.info ? 'Informacja' : 'Błąd'}</h2>
                        </div>
                        <div className="modal-body">
                            <div className="modal-wrapper">
                                <p className="modal-text">{modalMessage}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="modal-single-button-wrapper"> 
                                <button className={modal.info ? "modal-single-button" : "modal-single-button modal-single-error-button"} onClick={closeModal}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};