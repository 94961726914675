import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext, Axios } from './UserContext';

export default function Admin(){

    // --- Użytkownik i wylogowanie ---

    const {logout} = useContext(UserContext);

    return (
        <div id="admin-app-outer-container">
            
        </div>
    );

};